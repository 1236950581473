<template>
    <div class="content-wrapper">
        <rq-banner
            ref="errorBanner"
            :message="errorMessage"
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="errorMessage.length > 0"
        />
        <div class="row ps-3 pe-3">
            <div :class="{ 'col col-12 form-group form-required':true, 'has-error': v$.fileType.$error }">
                <label for="drp_save_as_file_type">Save As File Type</label>
                <rqSelectBox
                    automation_id="drp_save_as_file_type"
                    :items="saveAsItems"
                    v-model="v$.fileType.$model"
                    />
                    <rq-validation-feedback
                        :messages="{ 'File Type is required': v$.fileType.required.$invalid }"
                    />
            </div>
        </div>
        <div class="row ps-3 pe-3" v-if="showZip">
            <div class="col col-12 form-group">
                <b-form-checkbox
                    automation_id="chk_zip_results"
                    id="chk_zip_results"
                    v-model="saveAsZip">Zip {{noun}}
                </b-form-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";

    export default {
        name: 'DocumentsSaveAsDialog',
        components: { },
        props: {
            saveAsItems: { type: Array, required: true, default: () => [] },
            showZip: { type: Boolean, required: false, default: false },
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                fileType: null,
                saveAsZip: false,
                errorMessage: ""
            }
        },
        computed: {
            noun() { return _.size(this.saveAsItems) > 1 ? "Documents" : "Document"; },
        },
        validations() {
            var result = {
                fileType: { required }
            };

            return result;
        },
        watch: {
            "v$.$error":{
                handler: function(newValue, oldValue) {
                    this.$emit(`${newValue ? "disable" : "enable"}-ok`);
                },
                deep: true,
            },
        },
    }
</script>