import DocumentMgmtMain from "./DocumentMgmtMain";
import { ViewActions } from "@/router/actions/document-mgmt";

export default {
    name: "document-mgmt",
    path: "document-mgmt",
    component: DocumentMgmtMain,
    meta: {
        entity: "Document Management",
        label: "Document Management",
        permissionKeys: ["appFileScan_AppAccess"],
        actions: ViewActions.MAIN,
        skipSaveOnLeave: true,
        include: true
    }
};
