const fileScanDocumentDuplicateAction = { Ignore: -1, CreateNew: 1, ReplaceExisting: 2 };
export class FileScanDocumentDuplicateAction {
  static get Ignore () {return fileScanDocumentDuplicateAction.Ignore;}
  // static get Upload () {return fileScanDocumentDuplicateAction.Upload;}
  static get CreateNew () {return fileScanDocumentDuplicateAction.CreateNew;}
  static get ReplaceExisting () {return fileScanDocumentDuplicateAction.ReplaceExisting;}

  static get lookupItems () {
      return _.map(fileScanDocumentDuplicateAction, c => { return { id: c, name: FileScanDocumentDuplicateAction.displayValue(c) }; });
  }

  static displayValue (id) {
    switch (id) {
      // case fileScanDocumentDuplicateAction.Upload:
      //     return "";
      case fileScanDocumentDuplicateAction.Ignore:
          return "Ignore";
      case fileScanDocumentDuplicateAction.CreateNew:
          return "Create New";
      case fileScanDocumentDuplicateAction.ReplaceExisting:
          return "Replace Existing";
    }
    return "Unknown";
  }
}

const fileScanDocumentAction = { NoPreview: -1, Preview: 0, Download: 1, DownloadZip: 2, Edit: 3, Print: 4 };
export class FileScanDocumentAction {
  static get NoPreview () {return fileScanDocumentAction.NoPreview;}
  static get Preview () {return fileScanDocumentAction.Preview;}
  static get Download () {return fileScanDocumentAction.Download;}
  static get DownloadZip () {return fileScanDocumentAction.DownloadZip;}
  static get Edit () {return fileScanDocumentAction.Edit;}
  static get Print () {return fileScanDocumentAction.Print;}

  static get lookupItems () {
      return _.map(fileScanDocumentAction, c => { return { id: c, name: FileScanDocumentAction.displayValue(c) }; });
  }

  static displayValue (id) {
    let enumKey = _.findKey(fileScanDocumentAction, val => val === id);
    return (enumKey && _.startCase(enumKey)) || "";
}
}
