<template>
    <div class="content-wrapper">
        <div class="row ps-3 pe-3">
            <div class="col col-12 form-group">
                <label for="tb_tags">Tags</label>
                <dx-tag-box
                    automation_id="tb_tags"
                    ref="tb_tags"
                    class="form-control"
                    :data-source="documentTags"
                    display-expr="name"
                    value-expr="id"
                    :search-enabled="true"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="3"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    v-model="tagIDs"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import DxTagBox from "devextreme-vue/tag-box";

    export default {
        name: 'FileScanDocumentAssignTag',
        components: { DxTagBox },
        props: {
            documentTags: {type: Array, required: true, default: () => []},
            originalTagIDs: {type: Array, required: false, default: () => []}
        },
        data() {
            return {
                tagIDs: []
            }
        },
        created() {
            this.tagIDs = this.originalTagIDs;
        },
    }
</script>
<style lang="scss" scoped>
.dx-list-select-all {
    display: none;
}
</style>